import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    tasksList: [],
    isLoaded: false,
    lenderTaskcounts: {

    }

}

const taskListsReducer = createSlice({
    name: "taskListsReducer",
    initialState: defaultState,
    reducers: {
        updateTasksList: (state, action) => {
            state = {
                ...state,
                tasksList: action.payload.taskList,
                isLoaded: true,
                lenderTaskcounts: action.payload.eventKey
            }
            return state;
        }
    }
})


export const { updateTasksList } = taskListsReducer.actions;

export default taskListsReducer.reducer;

