import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    drawdownRequest: {

    },
    currSectionChange: true

}

const drawdownRequestReducer = createSlice({
    name: "drawdownRequestReducer",
    initialState: defaultState,
    reducers: {
        updateDrawdownRequest: (state, action) => {
            state = {
                ...state,
                drawdownRequest: action.payload,
                currSectionChange: Object.keys(action.payload).length > 0 ? false : true
            }
            return state
        },
        updateSectionChange: (state, action) => {
            state = {
                ...state,
                currSectionChange: action.payload
            }
            return state
        }
    }
})

export const { updateDrawdownRequest, updateSectionChange } = drawdownRequestReducer.actions;

export default drawdownRequestReducer.reducer;