import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    commonRedirectLoader: false,
    commonTabStatus: '',
    commonFilter : {},
    pendingDealerFilters: {},
    completeDealerFilters: {},
    pendingSupplierFilters: {},
    completeSupplierFilters: {}
}

// const commonReducer = (state = defaultState, action) => {
//     switch (action.type) {
//         case 'REDIRECTLOADER':
//            console.log("test reducer")
//             const redirectLoader = {
//                 ...state,
//                 commonRedirectLoader: action.status
//             }
//             return redirectLoader;
//         default:
//             return state;
//     }
// }
// export default commonReducer;


const commonReducer = createSlice({
    name: "commonReducer",
    initialState: defaultState,
    reducers: {
        redirectLoader: (state, action) => {
            state = {
                ...state,
                commonRedirectLoader: action.payload
            }
            return state;
        },
        setTabStatus: (state, action) => {
            state = {
                ...state,
                commonTabStatus: action.payload.commonTabStatus,
                commonFilter: action.payload.commonFilter
            }
            return state;
        },
        setPendingDealerFilters: (state, action) => {
            state = {
                ...state,
                pendingDealerFilters: action.payload
            }
            return state;
        },
        setCompleteDealerFilters: (state, action) => {
            state = {
                ...state,
                completeDealerFilters: action.payload
            }
            return state;
        },
        setPendingSupplierFilters: (state, action) => {
            state = {
                ...state,
                pendingSupplierFilters: action.payload
            }
            return state;
        },
        setCompleteSupplierFilters: (state, action) => {
            state = {
                ...state,
                completeSupplierFilters: action.payload
            }
            return state;
        },
    }
})

export const { redirectLoader, setTabStatus, setPendingDealerFilters, setCompleteDealerFilters, setPendingSupplierFilters, setCompleteSupplierFilters } = commonReducer.actions

export default commonReducer.reducer