import React from 'react';
import { Form } from 'react-bootstrap';
import '../sharedFormControl.css'

const Password = (props) => {

    const onChange = (val) => {

        if (props.onChange)
            props.onChange(props.objectKey, val);

    }

    return (
        <React.Fragment>
            <fieldset className={`default-fieldset ${props.zeroMargin ? 'mb-0' : ''}`}>
                {!props.hideLegend ? <legend className='default-fieldset-label'>{props.label ? props.label : ''}</legend> : null}
                <Form.Control
                    type="password"
                    required={props.required}
                    name={props.objectKey}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={event => onChange(event.target.value)}
                    style={props.style ? props.style : {}}
                />
            </fieldset>
            <Form.Control.Feedback type="invalid">
                {props.errorMessage ? props.errorMessage : `Please enter a valid ${props.name}.`}
            </Form.Control.Feedback>
        </React.Fragment>
    )

}

export default Password;