import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Image, Col } from 'react-bootstrap';
import './Login.css';
// import siteLogo from '../../assets/images/logo.png';
import placeholderSiteLogo from '../../assets/images/placeholder-logo.png';
import callAPI from '../../commonservices/APIRequests';
import { encrypt } from '../../commonservices/CommonMethods';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import publicIp from "public-ip";
import Toaster from '../Shared/Toaster/Toaster';
import Cookies from 'universal-cookie';
import { getUA } from 'react-device-detect';
import Loader from '../Shared/Loader/Loader';
// import { useIdleTimer } from 'react-idle-timer';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from "react-router-dom";
import { hideLoader, loginData, logOut, setPatnerDetails, showLoader, upDateMenu } from '../../store/Reducers/Shared';
import { getMenu } from '../../store/Actions/Actions';
import Password from '../Shared/common/components/Shared_FormControls/Password/Password';
import Email from '../Shared/common/components/Shared_FormControls/Email/Email';
import TextBox from '../Shared/common/components/Shared_FormControls/TextBox/TextBox';

const Login = (props) => {

    const [loginState, setLoginState] = useState({
        username: '',
        password: '',
        validated: false,
        errorMessage: '',
        otpRoute: '',
        timeout: 3000 * 60 * 1,
        showModal: false,
        userLoggedIn: false,
        siteLogo: ''

    })
    const [isTimedOut, setIsTimedOut] = useState(false)
    const [partnerName, setpartnerName] = useState(localStorage.getItem('partnerName') ? localStorage.getItem('partnerName') : '')

    const navigate = useNavigate();
    const { isLoaded, userMenu } = useSelector((store) => store.sharedReducer);
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const onAction = (e) => {
        setIsTimedOut(false)
    }

    const onActive = (e) => {
        setIsTimedOut(false);
    }

    const onIdle = (e) => {
        navigate('/')


    }
    // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    //     element:document,
    //     onActive:onActive,
    //     onIdle:onIdle,
    //     onAction:onAction,
    //     debounce:250,
    //     timeout:loginState.timeout
    // })

    const toasterClose = () => {

        localStorage.removeItem('sessionTimeOut');

    }

    const onChangeField = (key, value) => {
        if(key.toLowerCase() === "username") {
            value = value.toLowerCase()
        }

        setLoginState({ ...loginState, [key]: value })

    }

    const saveDataAndRedirect = (response,userMenuData) => {

        if (response && response.data && response.data.message && response.data.message?.toLowerCase().indexOf('otp') > -1) {
            const loginUserData = response.data?.email ?? null;
            navigate(`/VerifyOTP?email=${loginUserData}`);
        }
        else {
           
            // if (response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'dealer') {
            //     localStorage.setItem('isMultiDealerAdmin', response.data.isMultiUser);
            // }else if (response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'supplier') {
            //     localStorage.setItem('isMultiSupplierAdmin', response.data.isMultiUser);
            // }else if (response.data.user.organization?.toLowerCase() === 'dealer' && response.data.user?.type?.toLowerCase() === 'application'){
            //     localStorage.setItem('OnBoardingTab', response.data.onBoardingTab);
            //     localStorage.setItem('onBoardFormSubmitted', response.data.onBoardFormSubmitted);
            //     localStorage.setItem('isUserProspect', true);
            //     localStorage.setItem('canCallGetData', true);
            // }
            localStorage.setItem('partner', JSON.stringify(response.data && response.data.partner ? response.data.partner : {}));
            dispatch(loginData(response.data.user))
            
            if (response.data.user.organization?.toLowerCase() === 'application' && response.data.user?.role?.toLowerCase() === 'admin'){
                localStorage.setItem('OnBoardingTab', response.data.onBoardingTab);
                localStorage.setItem('onBoardFormSubmitted', response.data.onBoardFormSubmitted);
                localStorage.setItem('isUserProspect', true);
                localStorage.setItem('canCallGetData', true);
                navigate('/DealerSignUp');
            }
            else if (response.data.user.organization?.toLowerCase() === 'dealer' && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'dealer'){
                localStorage.setItem('isMultiDealerAdmin', response.data.isMultiUser);
                navigate('/DealerAccess');
            }
            else if (response.data.user.organization?.toLowerCase() === 'supplier' && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'supplier'){
                localStorage.setItem('isMultiSupplierAdmin', response.data.isMultiUser);
                navigate('/SupplierAccess');
            }
            else{
                if (userMenuData?.length > 0)
                navigate(userMenuData[0]?.route)
            }
            
            // if (response.data.user.role.toLowerCase().indexOf('prospect') > -1) {
            // if (response.data.user.organization?.toLowerCase() === 'dealer' && response.data.user.role?.toLowerCase().indexOf('prospect') > -1) {
            //     localStorage.setItem('OnBoardingTab', response.data.onBoardingTab);
            //     localStorage.setItem('onBoardFormSubmitted', response.data.onBoardFormSubmitted);
            //     localStorage.setItem('isUserProspect', true);
            //     localStorage.setItem('canCallGetData', true);
            //     navigate('/DealerSignUp');
            // }else if (response.data.user.organization?.toLowerCase() === 'dealer' && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'dealer')
            //     navigate('/DealerAccess');
            //  else if (response.data.user.organization?.toLowerCase() === 'dealer')
            //     navigate(dealerDefaultRoute);
            // else if (response.data.user.organization?.toLowerCase() === 'supplier' && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'supplier')
            //     navigate('/SupplierAccess');  
            // else if (response.data.user.organization?.toLowerCase === 'supplier')
            //     navigate(supplierDefaultRoute);
            // else if (response.data.user.organization?.toLowerCase() === 'lender')
            //     navigate(lenderDefaultRoute);

            // else if (response.data.user.role.toLowerCase().indexOf('dealer') > -1 && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'dealer')
            //     navigate('/DealerAccess');
            // else if (response.data.user.role.toLowerCase().indexOf('dealer') > -1)
            //     navigate(dealerDefaultRoute);
            // else if (response.data.user.role.toLowerCase().indexOf('supplier') > -1 && response.data.isMultiUser && response.data.isMultiUserType?.toLowerCase() === 'supplier')
            //     navigate('/SupplierAccess');
            // else if (response.data.user.role.toLowerCase().indexOf('supplier') > -1)
            //     navigate(supplierDefaultRoute);
            // else if (response.data.user.role.toLowerCase() === 'lender')
            //     navigate(lenderDefaultRoute);
            // else if (response.data.user.role.toLowerCase().indexOf('credit') > -1)
            //     navigate('/Admin/Pipeline');
            // else if (response.data.user.role.toLowerCase().indexOf('admin') > -1)
            //     navigate('/Dealer');
            // else if (response.data.user.role.toLowerCase().indexOf('manager') > -1)
            //     navigate('/Dealer');
            // else if (response.data.user.role.toLowerCase() === 'rsm')
            //     navigate('/Admin');
            // else if (response.data.user.role.toLowerCase() === 'auditor')
            //     navigate(lenderDefaultRoute);
        }


    }


    const handleSubmit = async (event) => {

        event.preventDefault();
        event.stopPropagation();
        setLoginState({
            ...loginState,
            validated: true
        });


        const form = event.currentTarget;
        if (form.checkValidity() !== false) {

            // props.showLoader();
            dispatch(showLoader())

            let ipAddress = await publicIp.v4();

            const req = {
                username: loginState.username,
                email: loginState.email,
                password: loginState.password,
                ipAddress: ipAddress ? ipAddress : '',
                macAddres: '',
                isTrusted: cookies.get('isTrustedDevice') ? true : false,
                deviceName: getUA
            }

            req.password = encrypt(req.password);

            callAPI.post('/auth/api/v1/auth/login', req)
                .then(async (response) => {
                    if (response.status === 200) {
                        const partnerDetails = localStorage.getItem('partnerDetails') ? JSON.parse(localStorage.getItem('partnerDetails')) : {};
                        if(Object.keys(partnerDetails)?.length === 0)
                            getPartnerDetails();
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        localStorage.setItem('dbVersion', response.data.uiBuildVersion);
                        if (response.data.partners) {
                            localStorage.setItem('partners', JSON.stringify(response.data.partners));
                        }

                        // const getmenuData = getMenu();
                        // await dispatch(getmenuData);
                        await callAPI.get('/workflow/api/v1/menu/getDynamicMenu', {}).then((menuResponse)=>{
                            if (menuResponse.status === 200 || menuResponse.status === 304) {
                            //    dispatch(upDateMenu(response2.data.dynamicMenu && response2.data.dynamicMenu.length > 0 ? response2.data.dynamicMenu : []))

                                callAPI.post('/sopra/api/v1/oauth/generateToken', {})
                                .then((response1) => {
    
                                    if (response1.status === 200) {
                                    
                                        saveDataAndRedirect(response,menuResponse.data.dynamicMenu);
    
                                        if (response.data && response.data.user && response.data.user.organization && response.data.user.organization?.toLowerCase() === 'lender') {
                                            callAPI.post('/sopra/api/v1/dealer/refreshCreditLine', {}).then((refreshResponse) => {
                                                if (refreshResponse.status === 200) {
                                                    // Do nothing
                                                }
                                            });
    
                                        }
                                    }
                                    else {
    
                                        setLoginState({
                                            ...loginState,
                                            errorMessage: 'something went wrong.'
                                        });
    
                                        // props.hideLoader();
                                        dispatch(hideLoader())
    
                                    }
    
                                })
                            }
                        })

                       

                    }
                    else {

                        setLoginState({
                            ...loginState,
                            errorMessage: response.message
                        });

                        // props.hideLoader();
                        dispatch(hideLoader())

                    }

                })

        }
    };

    const getPartnerDetails = async (event) => {
        const req = {
            partnerURL: window.location.host.indexOf('localhost') > -1 ? 'devc.leverauto.com' : window.location.host
        }

        callAPI.get('/auth/api/v1/auth/partnerdetail', req)
            .then((response) => {
                if (response.status === 200 || response.status === 304) {
                    localStorage.setItem('partnerName', response.data && response.data.data && response.data.data.name ? response.data.data.name : 'Lever Auto');
                    localStorage.setItem('partnerDetails', response.data && response.data.data && response.data.data ? JSON.stringify(response.data.data) : '');

                    props.initiateLogRocket();

                    setLoginState({
                        ...loginState,
                        siteLogo: response.data.data ? response.data.data.partnerLogo : ''
                    })
                    // props.setPartnerDetails(response.data ? response.data.data : '')
                    dispatch(setPatnerDetails(response.data ? response.data.data : ''));
                }
            });


    };

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        // props.hideLoader();
        dispatch(hideLoader())
        // props.setLogoutState();
        dispatch(logOut)
        getPartnerDetails();
    }, [])

    return (
        <React.Fragment>
            <IdleTimer
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={onAction}
                debounce={250}
                timeout={loginState.timeout} />
            <div className="login-page-wrapper">
                {
                    localStorage.getItem('sessionTimeOut') ?
                        <Toaster myClassName='error-toaster' onClose={toasterClose} header='Error' content='Your Session has Timed Out.'></Toaster>
                        : null
                }
                <Loader show={!isLoaded} styleType='fixed' />
                <Container className="text-center">
                    <div className="login-from white-bg-text">
                        <div className="full-width">
                            <Link to='/'>
                                <h1 className="site-title">
                                    <Image src={loginState.siteLogo ? loginState.siteLogo : placeholderSiteLogo} className="site-logo" />

                                </h1>
                            </Link>
                            <h2>Welcome to the {partnerName} Portal</h2>
                            <h5>Please Sign-in to your account</h5>
                            <Form
                                noValidate
                                validated={loginState.validated}
                                onSubmit={handleSubmit}
                                className="text-left"
                            >
                                <Form.Group controlId="formBasicEmail">
                                    {/* <Email
                                        required
                                        label="Email"
                                        name="Email"
                                        objectKey="email"
                                        value={loginState.email}
                                        placeholder="john@example.com"
                                        toLowerCase
                                        onChange={(key, value) => onChangeField(key, value)}
                                    /> */}
                                    <TextBox
                                        required
                                        label="User Name"
                                        name="User Name"
                                        objectKey="username"
                                        placeholder="User Name"
                                        value={loginState.username}
                                        toLowerCase
                                        onChange={(key, value) => onChangeField(key, value)}
                                    />
                                    <div className='login-other-action-block'>
                                        <Link className="login-other-action-link" to='/ForgotUserName'>Forgot User Name?</Link>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword" className='mb-0'>
                                    <Password
                                        zeroMargin
                                        required
                                        label="Password"
                                        name='Password'
                                        objectKey='password'
                                        value={loginState.password}
                                        placeholder="Password"
                                        onChange={(key, value) => onChangeField(key, value)}
                                    />
                                </Form.Group>
                                <div className='login-other-action-block'>
                                    <Link className="login-other-action-link" to='/ForgotPassword'>Forgot Password?</Link>
                                </div>
                                <Form.Row className="align-items-center mx-0 pb-4 border-bottom-E6E8F0">
                                    <Col className="text-center p-0">
                                        <Button className="default-btn full-width-btn" type="submit" variant="primary">Sign in</Button>
                                        {loginState.errorMessage === '' ? '' : <div className="custom-error-message mt-2 mb-1">{loginState.errorMessage}</div>}
                                        <Link className="mt-3 text-center display-block text-center signup-btn" to='/DealerSignUp'> Dealer Sign Up</Link>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}


export default Login;
