import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists: false,
}

const NewApplicationDealersReducer = createSlice({
    name: "NewApplicationDealersReducer",
    initialState: defaultState,
    reducers: {
        setTrackerNewApplicationDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        trackerNAL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setTrackerNALExists: (state, action) => { 
            state = {
               ...state,
                dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setTrackerNewApplicationDealers, trackerNAL, setTrackerNALExists } = NewApplicationDealersReducer.actions;

export default NewApplicationDealersReducer.reducer;