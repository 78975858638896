import { createSlice } from "@reduxjs/toolkit";

const defaultState = {


    inventoryList: [],
    initialInventoryList:[],
    isLoaded: false,
    isInventoryDataLoader: true,
    vinSearch: ''

}

const myInventoryReducer = createSlice({
    name: "myInventoryReducer",
    initialState: defaultState,
    reducers: {
        getMyInventory: (state, action) => {
            state = {
                ...state,
                inventoryList: action.payload?.inventoryList,
                isLoaded: true,
            }
            return state
        },
        setInitialInventory: (state, action) => {
            state = {
                ...state,
                initialInventoryList: action.payload,
            }
            return state;
        },
        setVinSearch: (state, action) => {
            state = {
                ...state,
                vinSearch: action.payload,
            }
            return state;
        },
        getMyInventoryLoader: (state, action) => {
            state = {
                ...state,
                isInventoryDataLoader: true,
            }
            return state
        },
        getMyInventoryLoaderHide: (state, action) => {
            state = {
                ...state,
                isInventoryDataLoader: false,
            }
            return state
        },
    }
})

export const { getMyInventory, setInitialInventory, setVinSearch,  getMyInventoryLoader,getMyInventoryLoaderHide } = myInventoryReducer.actions;

export default myInventoryReducer.reducer;