import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    paymentsHistory: [],
    isLoaded: false,
    vinSearchText: '',
    initialPaymentsHistory: [],
    showAdvancedFiltersAI: false,
    advancedFiltersAI: {},
    hasCalledInitial: false
}

const paymentsHistoryReducer = createSlice({
    name: "paymentsHistoryReducer",
    initialState: defaultState,
    reducers: {
        updatePaymentsHistory: (state, action) => {
            state = {
                ...state,
                paymentsHistory: action.payload,
                isLoaded: true
            }
            return state;
        },
        updatePaymentsHistoryLoader: (state, action) => {
            state = {
                ...state,
                isLoaded: action.payload
            }
            return state;
        },
        setVintextPayments: (state, action) => {
            state = {
                ...state,
                vinSearchText: action.payload
            }
            return state;
        },
        setInitialPaymentsHistory: (state, action) => {
            state = {
                ...state,
                initialPaymentsHistory: action.payload
            }
            return state;
        },
        setPaymentFilters: (state, action) => {
            state = {
                ...state,
                showAdvancedFiltersAI: action.payload
            }
            return state;
        },
        setPaymentHistoryAdvanceFilter: (state, action) => {
            state = {
                ...state,
                advancedFiltersAI: action.payload
            }
            return state;
        },
        setPaymentHasCalledInitial: (state, action) => {
            state = {
                ...state,
                hasCalledInitial: action.payload
            }
            return state;
        },

    }
})

export const { updatePaymentsHistory, updatePaymentsHistoryLoader, setVintextPayments, setInitialPaymentsHistory, setPaymentFilters, setPaymentHistoryAdvanceFilter, setPaymentHasCalledInitial } = paymentsHistoryReducer.actions

export default paymentsHistoryReducer.reducer;