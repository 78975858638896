import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    saveStatusChecker: false
}



const saveStatusChecker = createSlice({
    name: "saveStatusChecker",
    initialState: defaultState,
    reducers: {
        saveChecker: (state, action) => {
            state = {
                ...state,
                saveStatusChecker: action.payload
            }
            return state;
        }
    }
})

export const { saveChecker } = saveStatusChecker.actions

export default saveStatusChecker.reducer