import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    pendingRequests: [],
    isLoaded: false
}

const pendingRequestsReducer = createSlice({
    name: "pendingRequestsReducer",
    initialState: defaultState,
    reducers: {
        updatePendingRequests: (state, action) => {
            state = {
                ...state,
                pendingRequests: action.payload,
                isLoaded: true,
            }
            return state
        }
    }
})

export const { updatePendingRequests } = pendingRequestsReducer.actions;

export default pendingRequestsReducer.reducer;