import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists:false,
}

const UnderWritingDealersReducer = createSlice({
    name: "UnderWritingDealersReducer",
    initialState: defaultState,
    reducers: {
        setUnderWritingDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        onboardingUL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setULExists: (state, action) => {
            state = {
                ...state,
                 dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setUnderWritingDealers, onboardingUL, setULExists } = UnderWritingDealersReducer.actions;

export default UnderWritingDealersReducer.reducer;