import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists:false,
}

const UnderWritingDealersReducer = createSlice({
    name: "UnderWritingDealersReducer",
    initialState: defaultState,
    reducers: {
        setTrackerUnderwritingDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        trackerUL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setTrackerULExists: (state, action) => {
            state = {
                ...state,
                 dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setTrackerUnderwritingDealers, trackerUL, setTrackerULExists } = UnderWritingDealersReducer.actions

export default UnderWritingDealersReducer.reducer;