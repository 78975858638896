import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    initialTenDealers: [],
    dealerTenLists: [],
    dealerTenListsLoader: false,
    dealerTenListsAdvanceFilters: {},
    dealerTenListDataRefresh: false,
    dealerListsCurrentPage: 1,
    totalDealersCount: 0
}



const lenderDealerTenLists = createSlice({
    name: "lenderDealerTenLists",
    initialState: defaultState,
    reducers: {
        setInitialTenDealers: (state, action) => {
            state = {
                ...state,
                initialTenDealers: action.payload ? action.payload : state.initialTenDealers
            }
            return state
        },
        setDealerTenLists: (state, action) => {
            state = {
                ...state,
                dealerTenLists: action.payload ? action.payload : state.dealerTenLists,
            }
            return state;
        },
        setDealerTenListsLoader: (state, action) => {
            state = {
                ...state,
                dealerTenListsLoader: action.payload,
            }
            return state;
        },
        setDealerListsCurrentPage: (state, action) => {
            state = {
                ...state,
                dealerListsCurrentPage: action.payload ? action.payload : state.dealerListsCurrentPage,
            }
            return state;
        },
        setDealerTenListsAdvanceFilters: (state, action) => {
            state = {
                ...state,
                dealerTenListsAdvanceFilters: action.payload
            }
            return state;
        },
        setDealerTenListsDataRefresh: (state, action) => {
            state = {
                ...state,
                dealerTenListDataRefresh: action.payload
            }
            return state;
        },
        setTotalDealersCount: (state, action) => {
            state = {
                ...state,
                totalDealersCount: action.payload
            }
            return state;
        },
    }
})

export const { setDealerTenLists, setDealerTenListsLoader, setDealerListsCurrentPage, setInitialTenDealers, setDealerTenListsAdvanceFilters, setDealerTenListsDataRefresh, setTotalDealersCount } = lenderDealerTenLists.actions

export default lenderDealerTenLists.reducer;