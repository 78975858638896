import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    dealersCount: 0,
    loader: false,
    archiveAdvFilters: {}
}

const ArchivedDealersReducer = createSlice({
    name: "ArchivedDealersReducer",
    initialState: defaultState,
    reducers: {
        setArchivedDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        setArchivedCount: (state, action) => {
            state = {
                ...state,
                dealersCount: action.payload,
            }
            return state;
        },
        onboardingAL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setArchivedAdvFilters: (state, action) => {
            state = {
                ...state,
                archiveAdvFilters: action.payload,
            }
            return state;
        }
    }
})

export const { setArchivedDealers, onboardingAL, setArchivedCount, setArchivedAdvFilters } = ArchivedDealersReducer.actions

export default ArchivedDealersReducer.reducer;