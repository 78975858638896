import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    facilityDetails: {

    },
    isLoaded: false,

}

const facilityDetailsReducer = createSlice({
    name: "facilityDetailsReducer",
    initialState: defaultState,
    reducers: {
        updateFacilityDetails: (state, action) => {
            state = {
                ...state,
                facilityDetails: action.payload?.facilityDetails || {},
                isLoaded: true,
            }
            return state;
        }
    }
})

export const { updateFacilityDetails } = facilityDetailsReducer.actions

export default facilityDetailsReducer.reducer;