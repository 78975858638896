import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    auctionSuppliers: [],
    lienHolderSuppliers: [],
    floorPlanSuppliers: [],
    franchiseSuppliers: [],
    OEMSuppliers: [],
    auctionSuppliersLoader: false,
    lienHolderSuppliersLoader: false,
    floorPlanSuppliersloader: false,
    franchiseSuppliersloader: false,
    OEMSuppliersloader: false,
    supplierCounts: {}
}


const suppliersReducer = createSlice(({
    name: 'suppliers',
    initialState: defaultState,
    reducers: {
        setSupplierLoader: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action?.payload?.loaderStatus,
            }
            return state
        },
        setSupplierData: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action?.payload?.data?.length > 0 ? action.payload.data : [],
            }
            return state
        },
        updateSupplierData: (state, action) => {
            state = {
                ...state,
                [action.payload.type]: action.payload.data,
            }
            return state
        },
        setSupplierCounts: (state, action) => {
            state = {
                ...state,
                supplierCounts: action.payload,
            }
            return state
        }
    }

}))

export const { setSupplierData, setSupplierLoader, updateSupplierData, setSupplierCounts } = suppliersReducer.actions

export default suppliersReducer.reducer;