import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    terminatedDealers: [],
    terminatedDealersLoader: false,
    isLoaded: false,
    terminatedDealersCount: 0
}

const lenderTerminatedDealers = createSlice({
    name: "lenderTerminatedDealers",
    initialState: defaultState,
    reducers: {
        setTerminatedDealers: (state, action) => {
            state = {
                ...state,
                terminatedDealers: action.payload ? action.payload : state.terminatedDealers,
            }
            return state;
        },
        setTerminatedDealersCounts: (state, action) => {
            state = {
                ...state,
                terminatedDealersCount: action.payload,
            }
            return state;
        },
        setterminatedDealersLoader: (state, action) => {
            state = {
                ...state,
                terminatedDealersLoader: action.payload,
            }
            return state;
        },
        setTerminatedDealersLoaded: (state, action) => {
            state = {
                ...state,
                isLoaded: action.payload,
            }
            return state;
        }
    }
})

export const { setTerminatedDealers, setterminatedDealersLoader, setTerminatedDealersLoaded, setTerminatedDealersCounts } = lenderTerminatedDealers.actions

export default lenderTerminatedDealers.reducer;