import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    tasksList: [],
    isLoaded: false,
    lenderTaskcounts: {

    }

}

const lendertaskslistReducer = createSlice({
    name: "lendertaskslistReducer",
    initialState: defaultState,
    reducers: {
        updateTasksList: (state, action) => {
            state = {
                ...state,
                tasksList: action.payload.tasksList,
                isLoaded: true,
                lenderTaskcounts: action.payload.lenderTaskcounts
            }
            return state;
        }
    }
})


export const { updateTasksList } = lendertaskslistReducer.actions;

export default lendertaskslistReducer.reducer;

