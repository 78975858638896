import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import store from './store/Reducers';
import { interceptor } from './commonservices/APIRequests';
import { BrowserRouter as Router } from 'react-router-dom';


interceptor(store);

const container = document.getElementById('root');
const rootContainer = ReactDOM.createRoot(container);
rootContainer.render(
  // <React.StrictMode>
  <Provider store={store}>
  {/* Router is wrapped for entire app.js to use navigate for redirecting to login when token is expired */}
    <Router> 
      <App />
    </Router>
  </Provider>
  //  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();