import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists: false,
}

const DocumentCollectionDealersReducer = createSlice({
    name: "DocumentCollectionDealersReducer",
    initialState: defaultState,
    reducers: {
        setDocumentCollection: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        onboardingDCL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setDCLExists: (state, action) => {
            state = {
                ...state,
                 dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setDocumentCollection, onboardingDCL,setDCLExists } = DocumentCollectionDealersReducer.actions;

export default DocumentCollectionDealersReducer.reducer;