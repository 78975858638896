import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    initialAudits: [],
    initialAuditsInventory: [],
    initialAuditsInventoryTotalLength: 0,
    initialUpcomingCount: 0,
    audits: [],
    auditsInventory: [],
    auditsInventoryTotalLength: 0,
    auditsInventoryCurrentPage: 1,
    upcomingAudits: [],
    quickTrackDocs: [],
    quickTrackUpcomingDocs: [],
    auditsLoader: false,
    auditsInventoryLoader: false,
    upcomingAuditsLoader: false,
    quickTrackDocsLoader: false,
    showAdvancedFiltersA: false,
    showAdvancedFiltersAI: false,
    showAdvancedFiltersUA: false,
    advancedFiltersA: {},
    advancedFiltersAI: {},
    advancedFiltersUA: {},
    vinSearchText: '',
    tempAudits: [],
    AuditDashboardInitialCall: false,
    initialInnerJSXConstructedAudits: false,
    auditCounts: {},
    followupStatus: {},
    audit30daysLabel: false,
    auditsDataExists: false,
    upcomingAuditsDataExists: false,
    lenderAuditsCount: 0,
    upcomingAuditsCount: 0,
    initialAuditsCounts: 0,
    isUpcomingFilterApplied: false,
    assignAuditorsList: [],
    assignAuditorRefreshApiCall: false
}

const lenderAudits = createSlice({
    name: "lenderAudits",
    initialState: defaultState,
    reducers: {
        setInitialTotalLengthAi: (state, action) => {
            state = {
                ...state,
                initialAuditsInventoryTotalLength: action.payload
            }
            return state;
        },
        setTotalLengthAi: (state, action) => {
            state = {
                ...state,
                auditsInventoryTotalLength: action.payload
            }
            return state
        },
        setCurrentPageAi: (state, action) => {
            state = {
                ...state,
                auditsInventoryCurrentPage: action.payload
            }
            return state;
        },
        setTempAudits: (state, action) => {
            state = {
                ...state,
                tempAudits: action.payload
            }
            return state;
        },
        setAuditDashboardInitialCall: (state, action) => {
            state = {
                ...state,
                AuditDashboardInitialCall: action.payload
            }
            return state;
        },
        setIijca: (state, action) => {
            state = {
                ...state,
                initialInnerJSXConstructedAudits: action.payload
            }
            return state;
        },
        auditSetVinText: (state, action) => {
            state = {
                ...state,
                vinSearchText: action.payload
            }
            return state;
        },
        setInitialLenderAudits: (state, action) => {
            state = {
                ...state,
                initialAudits: action.payload
            }
            return state;
        },
        setLenderAudits: (state, action) => {
            state = {
                ...state,
                audits: action.payload
            }
            return state;
        },
        setLenderAuditsCount: (state, action) => {
            state = {
                ...state,
                lenderAuditsCount: action.payload
            }
            return state;
        },
        setInitialLenderAuditsCount: (state, action) => {
            state = {
                ...state,
                initialAuditsCounts: action.payload
            }
            return state;
        },
        setUpcomingAuditsCount: (state, action) => {
            state = {
                ...state,
                upcomingAuditsCount: action.payload
            }
            return state;
        },
        setInitialLenderAuditsInventory: (state, action) => {
            state = {
                ...state,
                initialAuditsInventory: action.payload
            }
            return state;
        },
        setLenderAuditsInventory: (state, action) => {
            state = {
                ...state,
                auditsInventory: action.payload
            }
            return state;
        },
        setLenderUpcomingAudits: (state, action) => {
            state = {
                ...state,
                upcomingAudits: action.payload
            }
            return state;
        },
        setLenderQuickTrackDocs: (state, action) => {
            state = {
                ...state,
                quickTrackDocs: action.payload
            }
            return state;
        },
        setLenderQuickTrackUpcomingDocs: (state, action) => {
            state = {
                ...state,
                quickTrackUpcomingDocs: action.payload
            }
            return state;
        },
        loaderAudits: (state, action) => {
            state = {
                ...state,
                auditsLoader: action.payload
            }
            return state;
        },
        loaderAuditsInv: (state, action) => {
            state = {
                ...state,
                auditsInventoryLoader: action.payload
            }
            return state;
        },
        loaderUpcomingAudits: (state, action) => {
            state = {
                ...state,
                upcomingAuditsLoader: action.payload
            }
            return state;
        },
        loaderQuickTrackDocs: (state, action) => {
            state = {
                ...state,
                quickTrackDocsLoader: action.payload
            }
            return state;
        },
        setShowafAudits: (state, action) => {
            state = {
                ...state,
                showAdvancedFiltersA: action.payload
            }
            return state;
        },
        setShowafAuditsInv: (state, action) => {
            state = {
                ...state,
                showAdvancedFiltersAI: action.payload
            }
            return state;
        },
        setShowAfupcomingAudits: (state, action) => {
            state = {
                ...state,
                showAdvancedFiltersUA: action.payload
            }
            return state;
        },
        setAfAudits: (state, action) => {
            state = {
                ...state,
                advancedFiltersA: action.payload
            }
            return state;
        },
        setAfauditsInv: (state, action) => {
            state = {
                ...state,
                advancedFiltersAI: action.payload
            }
            return state;
        },
        setAfupcomingAudits: (state, action) => {
            state = {
                ...state,
                advancedFiltersUA: action.payload
            }
            return state;
        },
        setAuditCounts: (state, action) => {
            state = {
                ...state,
                auditCounts: action.payload
            }
            return state;
        },
        setFollowupStatus: (state, action) => {
            state = {
                ...state,
                followupStatus: action.payload
            }
            return state;
        },
        setAudit30daysLabel: (state, action) => {
            state = {
                ...state,
                audit30daysLabel: action.payload
            }
            return state;
        },
        setAuditsDataExists: (state, action) => {
            state = {
                ...state,
                auditsDataExists: action.payload
            }
            return state;
        },
        setUpcomingAuditsDataExists: (state, action) => {
            state = {
                ...state,
                upcomingAuditsDataExists: action.payload
            }
            return state;
        },
        setUpcomingFilterApplied: (state, action) => {
            state = {
                ...state,
                isUpcomingFilterApplied: action.payload
            }
            return state;
        },
        setAuditorsList: (state, action) => {
            state = {
                ...state,
                assignAuditorsList: action.payload
            }
            return state;
        },
        setAssignAuditorRefreshApiCall: (state, action) => {
            state = {
                ...state,
                assignAuditorRefreshApiCall: action.payload
            }
            return state;
        }
    }
})

export const { setInitialTotalLengthAi,
    setTotalLengthAi,
    setCurrentPageAi,
    setTempAudits,
    setAuditDashboardInitialCall,
    setIijca,
    auditSetVinText,
    setInitialLenderAudits,
    setLenderAudits,
    setInitialLenderAuditsInventory,
    setLenderAuditsInventory,
    setLenderUpcomingAudits,
    setLenderQuickTrackDocs,
    setUpcomingFilterApplied,
    setLenderQuickTrackUpcomingDocs,
    loaderAudits,
    loaderAuditsInv,
    loaderUpcomingAudits,
    loaderQuickTrackDocs,
    setShowafAudits,
    setShowafAuditsInv,
    setShowAfupcomingAudits,
    setAfAudits,
    setAfauditsInv,
    setAfupcomingAudits,
    setAuditCounts,
    setFollowupStatus,
    setLenderAuditsCount,
    setInitialLenderAuditsCount,
    setUpcomingAuditsCount,
    setAudit30daysLabel,
    setAuditsDataExists,
    setUpcomingAuditsDataExists,
    setAuditorsList,
    setAssignAuditorRefreshApiCall
} = lenderAudits.actions

export default lenderAudits.reducer;