import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    calendarEvents: {

    },
    isDataLoaded: false

}

const calendarEventsReducer = createSlice({
    name: "calendarEventsReducer",
    initialState: defaultState,
    reducers: {
        updateCalendarEvent: (state, action) => {
            state = {
                ...state,
                calendarEvents: action.payload,
                isDataLoaded: true
            }
            return state;
        }
    }
})

export const { updateCalendarEvent } = calendarEventsReducer.actions

export default calendarEventsReducer.reducer