import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    initialActiveDealers: [],
    activeDealers: [],
    activeDealersLoader: false,
    activeDealersAdvanceFilters: {},
    activeDealersDataRefresh: false,
    isLoaded:false
}
const lenderActiveDealers = createSlice({
    name: "lenderActiveDealers",
    initialState: defaultState,
    reducers: {
        setInitialActiveDealers: (state, action) => {
            state = {
                ...state,
                initialActiveDealers: action.payload ? action.payload : state.initialActiveDealers
            }
            return state
        },
        setActiveDealers: (state, action) => {
            state = {
                ...state,
                activeDealers: action.payload ? action.payload : state.activeDealers,
            }
            return state;
        },
        setActiveDealersLoader: (state, action) => {
            state = {
                ...state,
                activeDealersLoader: action.payload,
            }
            return state;
        },
        setActiveDealerAdvanceFilters: (state, action) => {
            state = {
                ...state,
                activeDealersAdvanceFilters: action.payload
            }
            return state;
        },
        setActiveDealersDataRefresh: (state, action) => {
            state = {
                ...state,
                activeDealersDataRefresh: action.payload
            }
            return state;
        },
        setActiveDealersLoaded: (state, action) => {
            state = {
                ...state,
                 isLoaded: action.payload
            }
            return state;
        }

    }
})

export const { setActiveDealers, setActiveDealersLoader, setInitialActiveDealers, setActiveDealerAdvanceFilters, setActiveDealersDataRefresh, setActiveDealersLoaded } = lenderActiveDealers.actions

export default lenderActiveDealers.reducer;