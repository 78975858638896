import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    makeOtherPayments: [],
    isLoaded: false,

}

const otherPaymentsReducer = createSlice({
    name: "otherPaymentsReducer",
    initialState: defaultState,
    reducers: {
        updateOtherPayments: (state, action) => {
            state = {
                ...state,
                makeOtherPayments: action.payload,
                isLoaded: true,
            }
            return state;
        }
    }
})

export const { updateOtherPayments } = otherPaymentsReducer.actions

export default otherPaymentsReducer.reducer;