import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists:false,
}

const ContractingDealersReducer = createSlice({
    name: "ContractingDealersReducer",
    initialState: defaultState,
    reducers: {
        setContractingDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        onboardingCL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setCLExists:(state, action) => {
            state = {
               ...state,
                dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setContractingDealers, onboardingCL, setCLExists } = ContractingDealersReducer.actions

export default ContractingDealersReducer.reducer;