import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    paymentsInDue: [],
    isLoaded: false,

}

const paymentsInDueReducer = createSlice({
    name: "paymentsInDueReducer",
    initialState: defaultState,
    reducers: {
        updateDuePayments: (state, action) => {
            state = {
                ...state,
                paymentsInDue: action.payload,
                isLoaded: true,
            }
            return state;
        }
    }
})

export const { updateDuePayments } = paymentsInDueReducer.actions;

export default paymentsInDueReducer.reducer;