import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists:false
}

const ContractingDealersReducer = createSlice({
    name: "ContractingDealersReducer",
    initialState: defaultState,
    reducers: {
        setTrackerContractingDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state
        },
        trackerCL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state
        },
        setTrackerCLExists: (state, action) => {
            state = {
                ...state,
                 dataExists: action.payload,
            }
            return state
        }
    }
})

export const { setTrackerContractingDealers, trackerCL,setTrackerCLExists } = ContractingDealersReducer.actions;

export default ContractingDealersReducer.reducer;