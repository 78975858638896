import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    initialRisksDealers: [],
    risksDealers: [],
    risksDealersLoader: false,
    risksDealersAdvanceFilters: {},
    risksDealersDataRefresh: false,
}



const lenderRisksDealers = createSlice({
    name: "lenderRisksDealers",
    initialState: defaultState,
    reducers: {
        setInitialRisksDealers: (state, action) => {
            state = {
                ...state,
                initialRisksDealers: action.payload ? action.payload : state.initialRisksDealers
            }
            return state
        },
        setRisksDealers: (state, action) => {
            state = {
                ...state,
                risksDealers: action.payload ? action.payload : state.risksDealers,
            }
            return state;
        },
        setRisksDealersLoader: (state, action) => {
            state = {
                ...state,
                risksDealersLoader: action.payload,
            }
            return state;
        },
        setRisksDealerAdvanceFilters: (state, action) => {
            state = {
                ...state,
                risksDealersAdvanceFilters: action.payload
            }
            return state;
        },
        setRisksDealersDataRefresh: (state, action) => {
            state = {
                ...state,
                risksDealersDataRefresh: action.payload
            }
            return state;
        }
    }
})

export const { setRisksDealers, setRisksDealersLoader, setInitialRisksDealers, setRisksDealerAdvanceFilters, setRisksDealersDataRefresh} = lenderRisksDealers.actions

export default lenderRisksDealers.reducer;