import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    paymentsInProgress: [],
    isLoaded: false,

}

const paymentsInProgressReducer = createSlice({
    name: "paymentsInProgressReducer",
    initialState: defaultState,
    reducers: {
        updatePaymentsInProgress: (state, action) => {
            state = {
                ...state,
                paymentsInProgress: action.payload,
                isLoaded: true,
            }
            return state
        }
    }
})

export const { updatePaymentsInProgress } = paymentsInProgressReducer.actions

export default paymentsInProgressReducer.reducer;