import React from 'react';
import { Form } from 'react-bootstrap';
import '../sharedFormControl.css'

const Email = (props) => {

    const onChange = (val) => {

        val = val ? val.toLowerCase() : val;

        if (props.onChange)
            props.onChange(props.objectKey, val);

    }

    return (
        <React.Fragment>
            <fieldset className='default-fieldset'>
                {!props.hideLegend ? <legend className='default-fieldset-label'>{props.label ? props.label : ''}</legend> : null}
                <Form.Control
                    type="text"
                    required={props.required}
                    name={props.objectKey}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    onChange={event => onChange(event.target.value)}
                    style={props.style ? props.style : {}}
                    pattern='[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$'
                />
            </fieldset>
            <Form.Control.Feedback type="invalid">
                {props.errorMessage ? props.errorMessage : `Please enter a valid ${props.name}.`}
            </Form.Control.Feedback>
        </React.Fragment>
    )

}

export default Email;