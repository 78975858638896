import callAPI from '../../commonservices/APIRequests'
import { setDealerFeatures, setSopraDealerList, setSupplierAssetTypes } from './Shared';

export const getSopraDealers = () => {
    

    return dispatch => {
        dispatch({ type: "ISSOPRADEALERSLOADED", isSopraDealersLoaded: true });
        return callAPI.get('/sopra/api/v1/sopra/getSopraDealers', {})
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setSopraDealerList(
                        response.data.dealers && response.data.dealers.length > 0 ? response.data.dealers.filter(item1 => item1.dealerId !== null).map(item => {
                            return ({
                                label: item.referenceName,
                                value: item.reference,
                                id: item.dealerId,
                                sopraId: item.id
                            })
                        }) : []
                    ))

                }
                else {
                    dispatch(setSopraDealerList([]))
                }

            })

    }

}

export const getDealerFeatures = (supplierRef = '',dealer_id) => {
    return dispatch => {
        let req = {};
        if(supplierRef){
            req.supplierReference = supplierRef
        }else if(dealer_id){
            req.dealer_id = dealer_id
        }
        // dispatch({ type: "DEALERFEATURES", dealerFeatures: {} });
        return callAPI.get('/lender/api/v1/dealer/getDealerCustomFeatures', req)
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setDealerFeatures(response.data.customFeatures));

                }
                else {
                    dispatch(setDealerFeatures({}));

                }

            })

    }

}

export const getSupplierAssetTypes = () => {

    return dispatch => {

        return callAPI.post('/lender/api/v1/dealer/getAuctionAssets')
            .then((response) => {

                if (response.status === 200) {

                    dispatch(setSupplierAssetTypes(response.data.assetTypes));

                }
                else {
                    dispatch(setSupplierAssetTypes([]));

                }

            })

    }

}