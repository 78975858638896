import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    inventoryHistory: [],
    isLoaded: false,
    vinSearchText: '',
    initialInventoryHistory: [],
    showAdvancedFiltersAI: false,
    advancedFiltersAI: {},
    hasCalledInitial: false

}

const inventoryHistoryReducer = createSlice({
    name: "inventoryHistoryReducer",
    initialState: defaultState,
    reducers: {
        updateInventoryHistory: (state, action) => {
            state = {
                ...state,
                inventoryHistory: action.payload,
                isLoaded: true
            }
            return state;
        },
        updateInventoryHistoryLoader: (state, action) => {
            state = {
                ...state,
                isLoaded: action.payload
            }
            return state;
        },
        setVintextInventory: (state, action) => {
            state = {
                ...state,
                vinSearchText: action.payload
            }
            return state;
        },
        setInitialInventoryHistory: (state, action) => {
            state = {
                ...state,
                initialInventoryHistory: action.payload
            }
            return state;
        },
        setInventoryFilters: (state, action) => {
            state = {
                ...state,
                showAdvancedFiltersAI: action.payload
            }
            return state;
        },
        setInventoryHistoryAdvanceFilter: (state, action) => {
            state = {
                ...state,
                advancedFiltersAI: action.payload
            }
            return state;
        },
        setInventoryHasCalledInitial: (state, action) => {
            state = {
                ...state,
                hasCalledInitial: action.payload
            }
            return state;
        },
    }
})


export const { updateInventoryHistory, updateInventoryHistoryLoader, setVintextInventory, setInitialInventoryHistory, setInventoryFilters, setInventoryHistoryAdvanceFilter, setInventoryHasCalledInitial } = inventoryHistoryReducer.actions

export default inventoryHistoryReducer.reducer;