import { combineReducers } from 'redux';
import sharedReducer from './Shared';
import bankdetailsReducer from './BankDetails';
import facilityDetailsReducer from './Dashboard/FacilityDetails'
import cartReducer from './Cart';
import notificationsReducer from './Notifications';
import drawdownRequestReducer from './DrawdownRequest';
import calendarEventsReducer from './CalendarEvents';
import taskListsReducer from './Dashboard/TasksDetails';
import myInventoryReducer from './Dashboard/Inventory';
import pendingRequestsReducer from './DrawDown/PendingRequests';
import suppliersListReducer from './DrawDown/SupplierList';
import paymentsInProgressReducer from './Payments/PaymentProgress';
import paymentsInDueReducer from './Payments/PaymentsDue';
import otherPaymentsReducer from './Payments/MakeOtherPayments';
import inventoryHistoryReducer from './History/InventoryHistory';
import paymentsHistoryReducer from './History/PaymentsHistory';
import lendertaskslistReducer from './Services/updateTaskslist';
import lenderActiveDealers from './Lender/Dashboard/activeDealers';
import lenderDealersCount from './Lender/Dashboard/dealersCount';
import lenderFrozenDealers from './Lender/Dashboard/fronzenDealers';
import lenderTagsDealers from './Lender/Dashboard/dealersByTags'
import lenderRisksDealers from './Lender/Dashboard/dealersByRisks'
import lenderDealerTenLists from './Lender/Dashboard/DealerTenLists';
import lenderActiveDealersForService from './Lender/Dashboard/activeDealersForServicing';
import lenderTerminatedDealers from './Lender/Dashboard/terminatedDealers';
import lenderInventoryReducer from './Lender/Inventory';
import fundingRequests from './Lender/FundingRequest/FundingRequest';
import servicingReducer from './Lender/Servicing/Servicing';
import auditsReducer from './Lender/Audits/Audits';
import commonReducer from './Lender/CommonReducer/CommonReducer';
import payments from './Lender/Payments/Payments';
import lenderFastFunding from './Lender/TitleAlerts/FastFunding/FastFunding';
import lenderTitleAuction from './Lender/TitleAlerts/Auction/Auction';
import lenderNonAuction from './Lender/TitleAlerts/NonAuction/NonAuction';
import lenderTitleOverdue from './Lender/TitleAlerts/Overdue/Overdue';
import sharedNotesReducer from './Notes';
import sharedTagsReducer from './Tags';
import saveStatusChecker from './Lender/saveStatusChecker/saveStatusChecker';
import suppliersReducer from './Lender/Suppliers/Suppliers'
//Pipeline Reducers
import pipelines from './Lender/Pipeline/Pipeline';
import oNewApplicationDealers from './Lender/Pipeline/Onboarding/NewApplicationDealers';
import oDocumentCollectionsDealers from './Lender/Pipeline/Onboarding/DocumentCollectionDealers';
import oUnderWritingDealers from './Lender/Pipeline/Onboarding/UnderwritingDealers';
import oContractingDealers from './Lender/Pipeline/Onboarding/ContractingDealers';
import oArchivedDealers from './Lender/Pipeline/Archive/ArchivedDealers';
import oRejectedDealers from './Lender/Pipeline/Archive/RejectedDealers';
import tNewApplicationDealers from './Lender/Pipeline/Tracker/NewApplicationDealers';
import tDocumentCollectionDealers from './Lender/Pipeline/Tracker/DocumentCollectionDealers';
import tUnderwritingDealers from './Lender/Pipeline/Tracker/UnderwritingDealers';
import tContractingDealers from './Lender/Pipeline/Tracker/ContractingDealers';

import { configureStore } from '@reduxjs/toolkit'

const appReducer = combineReducers({
    sharedReducer,
    bankdetailsReducer,
    saveStatusChecker,
    facilityDetailsReducer,
    cartReducer,
    notificationsReducer,
    drawdownRequestReducer,
    calendarEventsReducer,
    lenderFastFunding,
    lenderTitleAuction,
    lenderNonAuction,
    lenderTitleOverdue,
    taskListsReducer,
    myInventoryReducer,
    pendingRequestsReducer,
    suppliersListReducer,
    paymentsInProgressReducer,
    paymentsInDueReducer,
    otherPaymentsReducer,
    inventoryHistoryReducer,
    paymentsHistoryReducer,
    lendertaskslistReducer,
    lenderActiveDealers,
    lenderDealersCount,
    lenderFrozenDealers,
    lenderTagsDealers,
    lenderRisksDealers,
    lenderDealerTenLists,
    lenderActiveDealersForService,
    lenderTerminatedDealers,
    lenderInventoryReducer,
    fundingRequests,
    servicingReducer,
    auditsReducer,
    commonReducer,
    payments,
    pipelines,
    oNewApplicationDealers,
    oDocumentCollectionsDealers,
    oUnderWritingDealers,
    oContractingDealers,
    oArchivedDealers,
    oRejectedDealers,
    tNewApplicationDealers,
    tUnderwritingDealers,
    tContractingDealers,
    tDocumentCollectionDealers,
    sharedNotesReducer,
    suppliersReducer,
    sharedTagsReducer
})

const rootReducer = (state, action) => {

    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USER_LOGGED_OUT') {

        console.log("User Logged Out")
        state = undefined;

    }


    if (action.type === 'Shared/setDealerId') {

        const data = {
            ...state,
            myInventoryReducer: undefined,
            pendingRequestsReducer: undefined,
            drawdownRequestReducer: undefined,
            suppliersListReducer: undefined,
            paymentsInDueReducer: undefined,
            otherPaymentsReducer: undefined,
            paymentsInProgressReducer: undefined,
            paymentsHistoryReducer: undefined,
            inventoryHistoryReducer: undefined,
            cartReducer: undefined
        }
        state = data

    }


    return appReducer(state, action);

}

export default configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })

})