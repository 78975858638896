
import './App.css';
import './components/Shared/common/css/common.css';
import './components/Shared/common/css/colors.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Login from './components/Login/Login.js';
import { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu, getStates, getLoanTypes, getTagTypes, getSampleBankDocuments } from './store/Actions/Actions';
import { getWindowHeight } from './store/Reducers/Shared'
import { getSopraDealers } from './store/Reducers/masterDataActions';
import callAPI from './commonservices/APIRequests';
import packageJson from '../package.json';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import placeholderFavicon from '../src/assets/images/placeholder-favicon.ico'
import TagManager from 'react-gtm-module'
import { debounce } from 'lodash';
import { getPaginationData } from './store/Actions/Lender/CommonAction/CommonAction';

setupLogRocketReact(LogRocket);
global.appVersion = packageJson.version ? packageJson.version : '';

const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "ForgotPassword" */'./components/ForgotPassword/ForgotPassword'));
const ChangePassword = React.lazy(() => import(/* webpackChunkName: "ChangePassword" */'./components/ChangePassword/ChangePassword'));
const ForgotUserName = React.lazy(() => import(/* webpackChunkName: "ForgotUserName" */'./components/ForgotUserName/ForgotUserName'));
const ChangeUserName = React.lazy(() => import(/* webpackChunkName: "ChangeUserName" */'./components/ChangeUserName/ChangeUserName'));
const DealerDashboard = React.lazy(() => import(/* webpackChunkName: "Dsahboardnew" */'./components/Dealer-Portal/Dealer-portal'));
const LenderDashboard = React.lazy(() => import(/* webpackChunkName: "Lenderportal" */'./components/Lender-Portal/Lender-portal'));
const AuctionDashboard = React.lazy(() => import(/* webpackChunkName: "AuctionPortal" */'./components/Auction-Portal/Auction-Portal'));
const Checkout = React.lazy(() => import(/* webpackChunkName: "checkounew" */'./components/Shared/Checkout/Checkout'));
const LoanRequest = React.lazy(() => import(/* webpackChunkName: "LoanRequestnew" */'./components/Shared/Checkout/LoanRequest/LoanRequest'));
const Profile = React.lazy(() => import(/* webpackChunkName: "Profilenew" */'./components/Dealer-Portal/Dashboard/Profile/Profile'));
const DealerSignup = React.lazy(() => import(/* webpackChunkName: "DealerSignup" */'./components/DealerOnboarding/NewOnBoarding'));
const VerifyOTP = React.lazy(() => import(/* webpackChunkName: "VerifyOTP" */'./components/VerifyOTP/VerifyOTP'));
const QRAttachments = React.lazy(() => import(/* webpackChunkName: "QRAttachments" */'./components/DealerOnboarding/QRAttachments/QRAttachments'));
const QRUploadDocuments = React.lazy(() => import(/* webpackChunkName: "QRUploadDocs" */'./components/Dealer-Portal/Dashboard/FundingRequest/Shared/QRUploadDocuments'));
const QRBosUploadDocuments = React.lazy(() => import(/* webpackChunkName: "QRUploadDocs" */'./components/Dealer-Portal/Dashboard/BosFundingRequest/BosShared/QRUploadDocuments'));
const MultiDealerPage = React.lazy(() => import(/* webpackChunkName: "Dsahboardnew" */'./components/Dealer-Portal/Dashboard/Profile/MultiDealerAccess/MultiDealerAccess'));
const MultiSupplierPage = React.lazy(() => import(/* webpackChunkName: "Dsahboardnew" */'./components/Auction-Portal/Dashboard/MultiSupplierAccess/MultiSupplierAccess'));

const QRUploadTaskDocuments = React.lazy(() => import('./components/Dealer-Portal/Dashboard/FundingRequest/Shared/QRUploadTaskDocuments'));

export const history = createBrowserHistory();


const App = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const storeData = useSelector((store) => store.sharedReducer);
    const isMultiDealerAdmin = localStorage.getItem('isMultiDealerAdmin');
    const isMultiSupplierAdmin = localStorage.getItem('isMultiSupplierAdmin');
    const partnerDetails = localStorage.getItem('partnerDetails') ? JSON.parse(localStorage.getItem('partnerDetails')) : {};
    const sharedReducerData = useSelector((store) => store.sharedReducer);
    const dealerSelectedId = localStorage.getItem('multiDealerId') ? localStorage.getItem('multiDealerId') : ''
    const supplierSelectedId = localStorage.getItem('multiSupplierId') ? localStorage.getItem('multiSupplierId') : ''
    const [displayHeight, setDisplayHeight] = useState(window.innerHeight);

    const updateDisplayHeight = () => {
        setDisplayHeight(window.innerHeight);
        dispatch(getWindowHeight(window.innerHeight))
    };

    // Debounced event handler to avoid frequent calls during resizing
    const debouncedUpdateDisplayHeight = debounce(updateDisplayHeight, 100);

    useEffect(() => {
        window.addEventListener('resize', debouncedUpdateDisplayHeight);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', debouncedUpdateDisplayHeight);
            // Clear any pending debounced function calls
            debouncedUpdateDisplayHeight.cancel();
        };
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--display-height', `${displayHeight}px`);
    }, [displayHeight]);

    useEffect(() => {

        const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

        if (localStorage.getItem('token') && (!storeData.userMenu || (storeData.userMenu && storeData.userMenu.length === 0)))
            dispatch(getMenu())
        if (localStorage.getItem('token') && storeData.isSopraDealersLoaded === false && (userDetails && userDetails.organization && userDetails.organization?.toLowerCase() === 'lender'))
            dispatch(getSopraDealers())
        if (localStorage.getItem('token'))
            dispatch(getPaginationData())
    }, [storeData.userDetails])

    useEffect(() => {

        initiateLogRocket();

    }, []);

    useEffect(() => {
        if (storeData.masterData.states.length === 0)
            dispatch(getStates())

    }, [storeData, storeData.masterData.states])

    useEffect(() => {
        // if token is expired there then redirected to loginscreen
        if (storeData.expire_token) {
            navigate('/DealerLogin')
            dispatch({ type: 'USER_LOGGED_OUT' })
        }
    }, [storeData.expire_token])

    useEffect(() => {

        if (process.env.REACT_APP_ENVIRONMENT === 'production') {

            const userDetails = JSON.parse(localStorage.getItem('user'));

            if (userDetails) {
                LogRocket.identify(userDetails._id,
                    {
                        name: userDetails.dealerName ? userDetails.dealerName : userDetails.firstName,
                        email: userDetails.email

                    });
            }

        }

    }, [storeData.userDetails])

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production' && process.env.REACT_APP_MANAGER_ID) {
            TagManager.initialize({ gtmId: process.env.REACT_APP_MANAGER_ID });
        }
    }, []);



    const initiateLogRocket = () => {

        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            LogRocket.init(partnerDetails && partnerDetails.partnerShortCode && partnerDetails.partnerShortCode.toLowerCase() === 'bos' ?
                process.env.REACT_APP_BOS_LOGROCKET :
                partnerDetails?.partnerShortCode?.toLowerCase() === 'lnb' ?
                    process.env.REACT_APP_LAMAR_LOGROCKET :
                    process.env.REACT_APP_LOGROCKET);
        }

    }

    const versionCheckforReload = (versionA, versionB) => {
        const versionsA = versionA.split(/\./g);
        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());
            if (a === b) continue;
            return a > b || isNaN(b);
        }
        return false;
    };
    const checkBuildVersion = () => {

        callAPI.get(window.location.origin + '/meta.json?_=' + new Date().getTime())
            .then((jsonDetails) => {
                const localVersion = global.appVersion;
                if (jsonDetails.status === 200) {

                    const metaDataversion = jsonDetails.data && jsonDetails.data.version ? jsonDetails.data.version : '';
                    const fullyLoaded = JSON.parse(localStorage.getItem('isReloaded'));
                    console.log("localversion", localVersion);
                    console.log("metaDataversion", metaDataversion)
                    const isReload = versionCheckforReload(metaDataversion, localVersion);
                    if (isReload && !fullyLoaded) {
                        localStorage.setItem('isReloaded', true);
                        if (caches) {
                            caches.keys().then(async function (names) {
                                await Promise.all(names.map(name => caches.delete(name)));
                            });
                        }
                        //window.location.reload();
                    }

                }

                else {


                }

            })
    }

    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = partnerDetails.favicon ? partnerDetails.favicon : placeholderFavicon;
    }, []);

    useEffect(() => {

        // checkBuildVersion();

    }, []);

    const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

    return (
        <div className={`App ${userData.readOnlyAccess ? 'read-only-access' : ''}`}>
            <Routes>
                <Route path='/' exact element={<Login initiateLogRocket={initiateLogRocket} />} />
                {/* <Route path='/Landingpage' exact component={Landingpage} />
                <Route path='/AboutUs' exact component={Aboutpage} /> */}
                <Route path='/ForgotUserName' element={<Suspense fallback=''><ForgotUserName /></Suspense>} />
                <Route path='/ForgotPassword' element={<Suspense fallback=''><ForgotPassword /></Suspense>} />
                <Route path='/ChangePassword' element={<Suspense fallback=''><ChangePassword /></Suspense>} />
                <Route path='/ChangeUserName' element={<Suspense fallback=''><ChangeUserName /></Suspense>} />
                <Route path='/DealerSignup' element={<Suspense fallback=''><DealerSignup /></Suspense>} />
                <Route path='/DealerLogin' element={<Login initiateLogRocket={initiateLogRocket} />} />
                <Route path='/VerifyOTP' element={<Suspense fallback=''><VerifyOTP /></Suspense>} />
                <Route path='/QRAttachments' element={<Suspense fallback=''><QRAttachments /></Suspense>} />
                <Route path='/QRUploadTaskDocuments' element={<Suspense fallback=''><QRUploadTaskDocuments /></Suspense>} />
                <Route path='/QRUploadDocuments' element={<Suspense fallback=''><QRUploadDocuments /></Suspense>} />
                <Route path='/QRBosUploadDocuments' element={<Suspense fallback=''><QRBosUploadDocuments /></Suspense>} />
                <Route path='/DealerAccess' element={storeData.isUserAuthenticated && isMultiDealerAdmin ? (dealerSelectedId ? <Suspense fallback=''><DealerDashboard /></Suspense> : <Suspense fallback=''><MultiDealerPage /></Suspense>) : <Navigate to='/DealerLogin' />} />
                <Route path='/Dealer/*' element={storeData.isUserAuthenticated ? (!dealerSelectedId && isMultiDealerAdmin ? <Suspense fallback=''><MultiDealerPage /></Suspense> : <Suspense fallback=''><DealerDashboard /></Suspense>) : <Navigate to='/DealerLogin' />} />
                <Route path='/Admin/*' key={storeData.partnerDetails?.partnerId} element={storeData.isUserAuthenticated ? <Suspense fallback=''>{storeData.userMenu && storeData.userMenu.length ? <LenderDashboard /> : null}</Suspense> : <Navigate to='/DealerLogin' />} />
                <Route path='/SupplierAccess' element={storeData.isUserAuthenticated && isMultiSupplierAdmin ? (supplierSelectedId ? <Suspense fallback=''><AuctionDashboard /></Suspense> : <Suspense fallback=''><MultiSupplierPage /></Suspense>) : <Navigate to='/' />} />
                <Route path='/Supplier/*' element={storeData.isUserAuthenticated ? (!supplierSelectedId && isMultiSupplierAdmin ? <Suspense fallback=''><MultiSupplierPage /></Suspense> : <Suspense fallback=''>{storeData.userMenu && storeData.userMenu.length ? <AuctionDashboard /> : null}</Suspense>) : <Navigate to='/' />} />
                <Route path='/Checkout' element={storeData.isUserAuthenticated ? <Suspense fallback=''><Checkout /></Suspense> : <Navigate to='/' />} />
                <Route path='/loanrequest' element={storeData.isUserAuthenticated ? <Suspense fallback=''><LoanRequest /></Suspense> : <Navigate to='/' />} />
                <Route path='/profile' element={storeData.isUserAuthenticated ? <Suspense fallback=''><Profile /></Suspense> : <Navigate to='/' />} />
                <Route path='/:InvalidRoute' element={<h1 style={{ textAlign: 'center' }}>Not Found</h1>} />
            </Routes>
        </div>
    );

}


export default App;