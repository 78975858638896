import { createSlice } from "@reduxjs/toolkit"

const defaultState = {
    assetTagsInfoData: [],
    dealerTagsInfoData: []
}


const sharedTagsReducer = createSlice({
    name: "sharedTagsReducer",
    initialState: defaultState,
    reducers: {
        setAssetTagsInfoData: (state, action) => {
            state = {
                ...state,
                assetTagsInfoData: [...state.assetTagsInfoData, action.payload]
            }
            return state
        },
        setDealerTagsInfoData: (state, action) => {
            state = {
                ...state,
                dealerTagsInfoData: [...state.dealerTagsInfoData, action.payload]
            }
            return state;
        },
        updateTagsInfoData: (state, action) => {
            state = {
                ...state,
                assetTagsInfoData: action.payload.isAsset ? action.payload.updatedData : state.assetTagsInfoData,
                dealerTagsInfoData: !action.payload.isAsset ? action.payload.updatedData : state.dealerTagsInfoData
            }
            return state;
        }
    }
})

export const { setAssetTagsInfoData, setDealerTagsInfoData, updateTagsInfoData} = sharedTagsReducer.actions;

export default sharedTagsReducer.reducer;