import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    activeDealersForService:[],
}

const lenderActiveDealersForService = createSlice({
    name: "lenderActiveDealersForService",
    initialState: defaultState,
    reducers: {
        setDealersForServicing: (state, action) => {
            state = {
                ...state,
                activeDealersForService: action.payload,
            }
            return state
        },
    }
})

export const {setDealersForServicing} = lenderActiveDealersForService.actions;

export default lenderActiveDealersForService.reducer;