import './Loader.css'
import PlaceholderGif from '../../../assets/images/New-Loader.svg'

const Loader = (props) => {
    const classes = 'app-loader ' + props.styleType

    return props.show ? (<div className={classes}>
        <img className='loader-gif-icon' src={PlaceholderGif} alt='...Data is processing' />
    </div>) : null

}

export default Loader