import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    initialFrozenDealers: [],
    frozenDealers: [],
    frozenDealersLoader: false,
    frozenDealersAdvanceFilters: {},
    frozenDealersDataRefresh: false,
    isLoaded:false,
}



const lenderFrozenDealers = createSlice({
    name: "lenderFrozenDealers",
    initialState: defaultState,
    reducers: {
        setInitialFrozenDealers: (state, action) => {
            state = {
                ...state,
                initialFrozenDealers: action.payload ? action.payload : state.initialFrozenDealers
            }
            return state
        },
        setFrozenDealers: (state, action) => {
            state = {
                ...state,
                frozenDealers: action.payload ? action.payload : state.frozenDealers,
            }
            return state;
        },
        setfrozenDealersLoader: (state, action) => {
            state = {
                ...state,
                frozenDealersLoader: action.payload,
            }
            return state;
        },
        setFrozenDealerAdvanceFilters: (state, action) => {
            state = {
                ...state,
                frozenDealersAdvanceFilters: action.payload
            }
            return state;
        },
        setFrozenDealersDataRefresh: (state, action) => {
            state = {
                ...state,
                frozenDealersDataRefresh: action.payload
            }
            return state;
        },
        setFrozenDealersLoaded: (state, action) => {
            state = {
                ...state,
                 isLoaded: action.payload
            }
            return state;
        }
    }
})

export const { setFrozenDealers, setfrozenDealersLoader, setInitialFrozenDealers, setFrozenDealerAdvanceFilters, setFrozenDealersDataRefresh,setFrozenDealersLoaded} = lenderFrozenDealers.actions

export default lenderFrozenDealers.reducer;