import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    nonAuctionData: [],
    nonAuctionAlertLoader: false,
    nonAuctionDataExists: false,
}
const lenderNonAuction = createSlice({
    name: "lenderNonAuction",
    initialState: defaultState,
    reducers: {
        setNonAuctionData: (state, action) => {
            state = {
                ...state,
                nonAuctionData: action.payload ? action.payload : state.nonAuctionData,
            }
            return state;
        },
        setNonAuctionLoader: (state, action) => {
            state = {
                ...state,
                nonAuctionAlertLoader: action.payload,
            }
            return state;
        },
        setNonAuctionDataExists: (state, action) => {
            state = {
                ...state,
                nonAuctionDataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setNonAuctionData, setNonAuctionLoader, setNonAuctionDataExists } = lenderNonAuction.actions

export default lenderNonAuction.reducer;