import { createSlice, current } from "@reduxjs/toolkit";

const defaultState = {
    counts: {},
    headercounts: {},
    vehiclesFlooredList: [],
    aVehiclesFlooredList: [],
    naVehiclesFlooredList: [],
    aptVehiclesFlooredList: [],
    tasksList: [],
    tasksHistoryList: [],
    initialTasksHistoryList: [],
    initialVehicleHistoryList: [],
    vehiclesTasksHistoryList: [],
    showLoader: false,
    vehiclesFLLoader: false,
    aVehiclesFLLoader: false,
    aVehiclesFLDataExists:false,
    naVehiclesFLLoader: false,
    naVehiclesFLDataExists:false,
    aptVehiclesFLLoader: false,
    aptVehiclesFLDataExists:false,
    pendingLoanRequestLoader: false,
    flooredTaskLoader: false,
    flooredTaskDataExists:false,
    tasksHistoryLoader: false,
    historyAdvancedFilters: {},
    vinSearchText: '',
    vinVehicleSearchText: '',
    vehicleHistoryLoader: false,
    vehicleHistoryDataExists:false,
    taskHistoryLoader: false,
    taskHistoryDataExists:false,
    vehicleHistoryAdvancedFilters: {},
    pendingLoanRequestsData: []
}
const FundingRequests = createSlice({
    name: "FundingRequest",
    initialState: defaultState,
    reducers: {
        Counts: (state, action) => {
            state = {
                ...state,
                counts: action.payload.counts,
                showLoader: action.payload.showLoader
            }
            return state
        },
        FRHeaderCounts: (state, action) => {
            state = {
                ...state,
                headercounts: action.payload.headercounts,
                showLoader: action.payload.showLoader
            }
            return state
        },
        vehiclesFlooredList: (state, action) => {
            state = {
                ...state,
                vehiclesFlooredList: action.payload.vehiclesFlooredList ? action.payload.vehiclesFlooredList : state.vehiclesFlooredList,
                vehiclesFLLoader: action.payload.status
            }
            return state;
        },
        tasksList: (state, action) => {
            state = {
                ...state,
                tasksList: action.payload.tasksList,
                flooredTaskLoader: action.payload.flooredTaskLoader,
                flooredTaskDataExists:action?.payload?.dataExists,
            }
            return state;
        },
        setInitialHistory: (state, action) => {
            state = {
                ...state,
                initialTasksHistoryList: action.payload
            }
            return state;
        },
        tasksHistoryList: (state, action) => {
            state = {
                ...state,
                tasksHistoryList: action.payload ? action.payload : state.tasksHistoryList
            }
            return state;
        },
        setInitialVehicleHistory: (state, action) => {
            state = {
                ...state,
                initialVehicleHistoryList: action.payload
            }
            return state;
        },
        vehiclesTasksHistoryList: (state, action) => {
            state = {
                ...state,
                vehiclesTasksHistoryList: action.payload ? action.payload : state.vehiclesTasksHistoryList
            }
            return state;
        },
        historyAdvancedSearchFilters: (state, action) => {
            state = {
                ...state,
                historyAdvancedFilters: action.payload
            }
            return state;
        },
        vehicleHistoryAdvancedSearchFilters: (state, action) => {
            state = {
                ...state,
                vehicleHistoryAdvancedFilters: action.payload
            }
            return state;
        },
        loaderVehicleHistory: (state, action) => {
            state = {
                ...state,
                vehicleHistoryLoader: action.payload
            }
            return state;
        },
        setdataExistsVehicleHistory: (state, action) => {
            state = {
                ...state,
                vehicleHistoryDataExists: action.payload
            }
            return state;
        },
        loaderTaskHistory: (state, action) => {
            state = {
                ...state,
                taskHistoryLoader: action.payload
            }
            return state;
        },
        setTaskHistoryDataExists:(state,action)=>{
            state = {
                ...state,
                taskHistoryDataExists: action.payload
            }
            return state;
        },
        setTaskHistoryVinText: (state, action) => {
            state = {
                ...state,
                vinSearchText: action.payload
            }
            return state;
        },
        setVehicleHistoryVinText: (state, action) => {
            state = {
                ...state,
                vinVehicleSearchText: action.payload
            }
            return state;
        },
        showLoader: (state, action) => {
            state = {
                ...state,
                showLoader: action.payload
            }
            return state;
        },
        aVehiclesFlooredList: (state, action) => {
            state = {
                ...state,
                aVehiclesFlooredList: action.payload.vehiclesFlooredList ? action.payload.vehiclesFlooredList : state.aVehiclesFlooredList,
                aVehiclesFLLoader: action.payload.status,
                aVehiclesFLDataExists: action.payload.dataExists ?? state.aVehiclesFLDataExists
            }
            return state
        },
        naVehiclesFlooredList: (state, action) => {
            state = {
                ...state,
                naVehiclesFlooredList: action.payload.vehiclesFlooredList ? action.payload.vehiclesFlooredList : state.naVehiclesFlooredList,
                naVehiclesFLLoader: action.payload.status,
                naVehiclesFLDataExists: action.payload.dataExists ?? state.naVehiclesFLDataExists
            }
            return state
        },
        aptVehiclesFlooredList: (state, action) => {
            state = {
                ...state,
                aptVehiclesFlooredList: action.payload.vehiclesFlooredList ? action.payload.vehiclesFlooredList : state.aptVehiclesFlooredList,
                aptVehiclesFLLoader: action.payload.status,
                aptVehiclesFLDataExists: action.payload.dataExists ?? state.aptVehiclesFLDataExists
            }
            return state
        },
        pendingLoanRequestsList: (state, action) => {
            state = {
                ...state,
                pendingLoanRequestsData:action.payload.vehiclesFlooredList,
                pendingLoanRequestLoader:action.payload.status
            }
            return state
        }

    }
})

export const { Counts, FRHeaderCounts, vehiclesFlooredList, tasksList, setInitialHistory, tasksHistoryList, setInitialVehicleHistory, vehiclesTasksHistoryList, historyAdvancedSearchFilters, vehicleHistoryAdvancedSearchFilters,
    loaderVehicleHistory, loaderTaskHistory, setTaskHistoryVinText, setVehicleHistoryVinText, showLoader, aVehiclesFlooredList, naVehiclesFlooredList, aptVehiclesFlooredList,setdataExistsVehicleHistory,setTaskHistoryDataExists, pendingLoanRequestsList } = FundingRequests.actions;

export default FundingRequests.reducer;