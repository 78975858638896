import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    apisCalledAtleastOnce: false
}

const PipelinesReducer = createSlice({
    name:"PipelinesReducer",
    initialState:defaultState,
    reducers:{
        pipelineApiCalls:(state,action)=>{
            state={
                ...state,
                apisCalledAtleastOnce: action.payload,
            }
            return state;
        }
    }
})

export const {pipelineApiCalls} = PipelinesReducer.actions

export default PipelinesReducer.reducer;