import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    sharedNotesData: {
        dealerID: '',
        counts: {
            dealerNotesCount: 0,
            vehicleNotesCount: 0,
            titleNotesCount: 0,
            auditNotesCount: 0,
            paymentNotesCount: 0,
            historyNotesCount: 0
        },
        notes: {

        }
    },
    sharedNotesLoader: false,
    createNotesData:{
        notesData:{

        },
        noteType:'',
        showAddNote:false
    }
}



const sharedNotesReducer = createSlice({
    name: "sharedNotesReducer",
    initialState: defaultState,
    reducers: {
        setSharedNotes: (state, action) => {
            state = {
                ...state,
                sharedNotesData: {
                    dealerID: action.payload?.dealerId ? action.payload?.dealerId : '',
                    counts: action.payload?.counts ? action.payload?.counts : {
                        dealerNotesCount: 0,
                        vehicleNotesCount: 0,
                        titleNotesCount: 0,
                        auditNotesCount: 0,
                        paymentNotesCount: 0,
                        historyNotesCount: 0
                    },
                    notes: action.payload?.notesData ? {
                        ...state.sharedNotesData.notes,
                        ...action.payload?.notesData,

                    } : {}
                }
            }
            return state;
        },
        setSharedNotesLoader: (state, action) => {
            state = {
                ...state,
                sharedNotesLoader: action.payload
            }
            return state;
        },
        setCreateNotesData: (state, action) => {
            state = {
                ...state,
                createNotesData: {
                    notesData: action.payload?.notesData ? action.payload?.notesData : {},
                    noteType: action.payload?.noteType ? action.payload?.noteType : '',
                    showAddNote:action.payload?.showAddNote ? action.payload?.showAddNote : false,
                }
            }
            return state;
        }
    }
})

export const { setSharedNotes, setSharedNotesLoader,setCreateNotesData } = sharedNotesReducer.actions

export default sharedNotesReducer.reducer;