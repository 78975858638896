import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersCount:{},
}
const lenderDealersCount = createSlice({
    name: "lenderDealersCount",
    initialState: defaultState,
    reducers: {
        setDealersCount: (state, action) => {
            state = {
                ...state,
                dealersCount: action.payload,
            }
            return state
        },
    }
})

export const {setDealersCount} = lenderDealersCount.actions

export default lenderDealersCount.reducer;