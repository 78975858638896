import React from 'react';
import { Form } from 'react-bootstrap';
import '../sharedFormControl.css'

const TextBox = (props) => {

    const onChange = (val) => {

        if (props.pattern && !new RegExp(props.pattern).test(val)) {
            return; // Don't call the onChange prop if the value doesn't match the pattern
        }

        if (props.onChange)
            props.onChange(props.objectKey, val);

    }


    return (
        <React.Fragment>
            <fieldset className={`default-fieldset ${props.autoHeight ? 'InputAutoHeight' : null}`}>
                {!props.hideLegend ? <legend className='default-fieldset-label'>{props.label ? props.label : ''} {props.labelIcon ? props.labelIcon : ''}</legend> : null}
                <Form.Control
                    type="text"
                    disabled={props.disabled ? true : false}
                    required={props.required}
                    name={props.objectKey}
                    placeholder={props.placeholder}
                    value={props.value}
                    pattern={props.pattern}
                    maxLength={props.maxLength ? props.maxLength : null}
                    onChange={event => onChange(event.target.value)}
                    style={props.style ? props.style : {}}
                    onBlur={props.onBlur ? () => props.onBlur() : null}
                    minLength={props.minLength ? props.minLength : ''}
                    defaultValue={props?.defaultValue}
                    autoComplete={props?.autoComplete || !('autoComplete' in props) ? 'on' : 'off'}
                />
            </fieldset>
            <Form.Control.Feedback type="invalid">
                {props.errorMessage ? props.errorMessage : `Please enter a valid ${props.name}.`}
            </Form.Control.Feedback>
        </React.Fragment>
    )

}

TextBox.defaultProps = {
    pattern: '^.*$' // default pattern that matches everything
};

export default TextBox;