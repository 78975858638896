import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    dealersCount: 0,
    loader: false,
    deniedAdvFilters: {}
}

const RejectedDealersReducer = createSlice({
    name: "RejectedDealersReducer",
    initialState: defaultState,
    reducers: {
        setRejectedDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        setRejectedDealersCount: (state, action) => {
            state = {
                ...state,
                dealersCount: action.payload,
            }
            return state;
        },
        onboardingDL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setDeniedAdvFilters: (state, action) => {
            state = {
                ...state,
                deniedAdvFilters: action.payload,
            }
            return state;
        }
    }
})

export const { setRejectedDealers, onboardingDL, setRejectedDealersCount, setDeniedAdvFilters } = RejectedDealersReducer.actions

export default RejectedDealersReducer.reducer;