import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    notifications: [

    ]

}

const notificationsReducer = createSlice({
    name: "notificationsReducer",
    initialState: defaultState,
    reducers: {
        updateNotifications: (state, action) => {
            state = {
                ...state,
                notifications: action.payload
            }
            return state
        }
    }
})

export const { updateNotifications } = notificationsReducer.actions

export default notificationsReducer.reducer;