import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    suppliersList: [],
    isLoaded: false

}

const suppliersListReducer = createSlice({
    name: "suppliersListReducer",
    initialState: defaultState,
    reducers: {
        updateSuppliers: (state, action) => {
            state = {
                ...state,
                suppliersList: action.payload,
                isLoaded: true,
            }
            return state
        }
    }
})

export const { updateSuppliers } = suppliersListReducer.actions;

export default suppliersListReducer.reducer;