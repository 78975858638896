import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    fastFundingData: [],
    fastFundingAlertLoader: false,
    fastFundingDataExists:false,
}


const lenderFastFunding = createSlice({
    name: "lenderFastFunding",
    initialState: defaultState,
    reducers: {
        setFastFundingData: (state, action) => {
            state = {
                ...state,
                fastFundingData: action.payload ? action.payload : state.fastFundingData
            }
            return state
        },
        setFastFundingLoader: (state, action) => {
            state = {
                ...state,
                fastFundingAlertLoader: action.payload
            }
            return state
        },
        setFastFundingDataExists: (state, action) => { 
            state = {
               ...state,
                fastFundingDataExists: action.payload
            }
            return state
        }
    }
})

export const { setFastFundingData, setFastFundingLoader, setFastFundingDataExists } = lenderFastFunding.actions;

export default lenderFastFunding.reducer;