import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    bankpaymentData: {

    },
    isLoaded : false

}

const bankdetailsReducer = createSlice({
    name:"bankdetailsReducer",
    initialState:defaultState,
    reducers:{
        updateBankPayment:(state,action)=>{
            state={
                ...state,
                bankpaymentData: action.payload,
                isLoaded : true
            }
            return state
        },
        onSelectingBank:(state,action)=>{
            state={
                ...state,
                bankpaymentData: action.payload,
                isLoaded : true
            }
            return state
        }
    }
})


export const {updateBankPayment,onSelectingBank}= bankdetailsReducer.actions

export default bankdetailsReducer.reducer;