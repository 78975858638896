import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    initialTagsDealers: [],
    tagsDealers: [],
    tagsDealersLoader: false,
    tagsDealersAdvanceFilters: {},
    tagsDealersDataRefresh: false,
}



const lenderTagsDealers = createSlice({
    name: "lenderTagsDealers",
    initialState: defaultState,
    reducers: {
        setInitialTagsDealers: (state, action) => {
            state = {
                ...state,
                initialTagsDealers: action.payload ? action.payload : state.initialTagsDealers
            }
            return state
        },
        setTagsDealers: (state, action) => {
            state = {
                ...state,
                tagsDealers: action.payload ? action.payload : state.tagsDealers,
            }
            return state;
        },
        setTagsDealersLoader: (state, action) => {
            state = {
                ...state,
                tagsDealersLoader: action.payload,
            }
            return state;
        },
        setTagsDealerAdvanceFilters: (state, action) => {
            state = {
                ...state,
                tagsDealersAdvanceFilters: action.payload
            }
            return state;
        },
        setTagsDealersDataRefresh: (state, action) => {
            state = {
                ...state,
                tagsDealersDataRefresh: action.payload
            }
            return state;
        }
    }
})

export const { setTagsDealers, setTagsDealersLoader, setInitialTagsDealers, setTagsDealerAdvanceFilters, setTagsDealersDataRefresh} = lenderTagsDealers.actions

export default lenderTagsDealers.reducer;