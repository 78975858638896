import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    initialInventory: [],
    initialInventoryHistory: [],
    inventory: [],
    inventorySearch: [],
    inventorySearchTotalCount: 0,
    inventoryFilters: false,
    inventoryHistory: [],
    inventoryCounts: {},
    inventoryLoader: false,
    inventoryHistoryLoader: false,
    inventorySearchLoader: false,
    inventory120DaysLabel: false,
    inventoryHistory120DaysLabel: false,
    filterLabel: '',
    filterType: '',
    inventoryAdvancedSearchFilters: {},
    inventorySearchAdvancedFilters: {},
    inventoryHistoricalAdvancedSearchFilters: {},
    vinSearchText: '',
    vinSearchTextForInventorySearch: '',
    lenderTitleCounts: {},
    isAssetTagRefreshed: {},
    isGetInventoryBySearch: true,
    inventoryDataExists: false,
    inventoryHistoryDataExists: false,
    isFilterApplied: false,
    searchedFilters: {},
    shortctsData: {}
}

const lenderInventory = createSlice({
    name: "lenderInventory",
    initialState: defaultState,
    reducers: {
        SETVINTEXT: (state, action) => {
            state = {
                ...state,
                vinSearchText: action.payload
            }
            return state;
        },
        SETVINTEXTFORINVENTORYSEARCH: (state, action) => {
            state = {
                ...state,
                vinSearchTextForInventorySearch: action.payload
            }
            return state;
        },
        SETINITIALLENDERINVENTORY: (state, action) => {
            state = {
                ...state,
                initialInventory: action.payload
            }
            return state;
        },
        UPDATELENDERINVENTORY: (state, action) => {
            state = {
                ...state,
                inventory: action.payload
            }
            return state;
        },
        UPDATELENDERINVENTORYSEARCH: (state, action) => {
            state = {
                ...state,
                inventorySearch: action.payload,
            }
            return state;
        },
        UPDATELENDERINVENTORYFILTERS: (state, action) => {
            state = {
                ...state,
                inventoryFilters: action.payload.inventoryFilters,
                inventorySearchTotalCount: action.payload.totalCount,
            }
            return state;
        },
        SETLENDERINVENTORY: (state, action) => {
            state = {
                ...state,
                inventory: action.payload.inventory,
                inventoryCounts: action.payload.inventoryCounts,
                inventoryDataExists: action.payload.dataExists,
            }
            return state;
        },
        SETLENDERINVENTORYSEARCH: (state, action) => {
            state = {
                ...state,
                inventorySearch: action.payload.inventorySearch,
                inventoryCounts: action.payload.inventoryCounts,
                inventorySearchTotalCount: action.payload.totalCount,
                inventoryFilters: action.payload.inventoryFilters,
            }
            return state;
        },
        SETINITIALLENDERINVENTORYHISTORY: (state, action) => {
            state = {
                ...state,
                initialInventoryHistory: action.payload
            }
            return state;
        },
        UPDATELENDERINVENTORYHISTORY: (state, action) => {
            state = {
                ...state,
                inventoryHistory: action.payload
            }
            return state;
        },
        SETLENDERINVENTORYHISTORY: (state, action) => {
            state = {
                ...state,
                inventoryHistory: action.payload.inventoryHistory,
                inventoryHistoryDataExists:action.payload.dataExists
            }
            return state;
        },
        LOADERINV: (state, action) => {
            state = {
                ...state,
                inventoryLoader: action.payload
            }
            return state;
        },
        LOADERINVHIS: (state, action) => {
            state = {
                ...state,
                inventoryHistoryLoader: action.payload
            }
            return state;
        },
        LOADERINVSEARCH: (state, action) => {
            state = {
                ...state,
                inventorySearchLoader: action.payload,
            }
            return state;
        },
        INVENTORYADVANCEDSEARCHFILTERS: (state, action) => {
            state = {
                ...state,
                inventoryAdvancedSearchFilters: action.payload
            }
            return state;
        },
        INVENTORYSEARCHADVANCEDFILTERS: (state, action) => {
            state = {
                ...state,
                inventorySearchAdvancedFilters: action.payload
            }
            return state;
        },
        INVENTORY120DAYSLABEL: (state, action) => {
            state = {
                ...state,
                inventory120DaysLabel: action.payload
            }
            return state;
        },
        INVENTORYHISTORY120DAYSLABEL: (state, action) => {
            state = {
                ...state,
                inventoryHistory120DaysLabel: action.payload
            }
            return state;
        },
        setTitleCount: (state, action) => {
            state = {
                ...state,
                lenderTitlecounts: action.payload
            }
            return state;
        },
        INVENTORYHISTORICALADVANCEDSEARCHFILTERS: (state, action) => {
            state = {
                ...state,
                inventoryHistoricalAdvancedSearchFilters: action.payload
            }
            return state;
        },
        setAssetTagRefreshed: (state, action) => {
            state = {
                ...state,
                isAssetTagRefreshed: action.payload ? { refreshedTabName: action.payload } : {},
            }
            return state;
        },
        SETFILTERLABELTYPE: (state, action) => {
            state = {
                ...state,
                filterLabel: action.payload.filterLabel,
                filterType: action.payload.filterType,
                searchedFilters: action.payload.filtersData,
                shortctsData: action.payload.shortctsData
            }
            return state;
        },
        ISGETINVENTORYBYSEARCH: (state, action) => {
            state = {
                ...state,
                isGetInventoryBySearch: action.payload
            }
            return state;
        },
        setIsFilterApplied: (state, action) => {

            state = {
                ...state,
                isFilterApplied: action.payload
            }
            return state;
        }
    }
})


export const { SETVINTEXT, SETVINTEXTFORINVENTORYSEARCH, SETINITIALLENDERINVENTORY, UPDATELENDERINVENTORY,
    UPDATELENDERINVENTORYSEARCH, UPDATELENDERINVENTORYFILTERS, SETLENDERINVENTORY, SETLENDERINVENTORYSEARCH, SETINITIALLENDERINVENTORYHISTORY, UPDATELENDERINVENTORYHISTORY,
    SETLENDERINVENTORYHISTORY, LOADERINV, LOADERINVHIS, LOADERINVSEARCH, INVENTORYADVANCEDSEARCHFILTERS, INVENTORYSEARCHADVANCEDFILTERS,
    INVENTORY120DAYSLABEL, INVENTORYHISTORY120DAYSLABEL, setTitleCount, INVENTORYHISTORICALADVANCEDSEARCHFILTERS,
    setAssetTagRefreshed, ISGETINVENTORYBYSEARCH, SETFILTERLABELTYPE, setIsFilterApplied
} = lenderInventory.actions;

export default lenderInventory.reducer;