import axios from 'axios';
import { setToken, tokenExpire } from '../store/Reducers/Shared';
const apiURL = window.location.origin.indexOf('localhost') > -1 ? 'https://devc.leverauto.com' : window.location.origin;

const axiosApiInstance = axios.create({ baseURL: apiURL });

const hostName = window.location.origin.indexOf('localhost') > -1 ? 'devc.leverauto.com' : window.location.host

export const interceptor = (store, navigator) => {
    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
        async config => {
            const token = localStorage.getItem('token');
            if (token) {
                const state = store.getState();

                config.headers = {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'hostname': state.sharedReducer.partnerDetails && state.sharedReducer.partnerDetails.url ? state.sharedReducer.partnerDetails.url : hostName
                }

            }
            else {
                const state = store.getState();
                config.headers = {
                    'Accept': 'application/json',
                    'hostname': state.sharedReducer.partnerDetails && state.sharedReducer.partnerDetails.url ? state.sharedReducer.partnerDetails.url : hostName

                }
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use((response) => {

        if (response.status === 200 && response.data.token) {
            localStorage.setItem('token', response.data.token);
            store.dispatch(setToken(response.data.token));
        }
        return response
    }, async function (error) {


        if (error.response && error.response.status === 401 && (error.response.message === 'Unauthorized' || error.response.data.message === 'Unauthorized')) {
            localStorage.removeItem('token');
            localStorage.setItem('sessionTimeOut', true);
            store.dispatch(tokenExpire(true));
        }

        return Promise.reject(error);
    });

}

const callAPI = {

    get: (url, data) => {

        return axiosApiInstance.get(url, { params: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });


    },

    post: (url, data) => {

        return axiosApiInstance.post(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    del: (url, data) => {

        return axiosApiInstance.delete(url, { data: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    delWithParams: (url, data) => {

        return axiosApiInstance.delete(url, { params: data })
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    patch: (url, data) => {

        return axiosApiInstance.patch(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },

    put: (url, data) => {

        return axiosApiInstance.put(url, data)
            .then(response => response)
            .catch(error => { return callAPI.catchError(error) });

    },


    catchError: (response) => {
        const res = {
            message: '',
            status: response.response ? response.response.status : null
        }

        if(response?.response?.data?.data){
            res['data'] = response?.response?.data?.data;
        }

        if (response.response && response.response.data.message && response.response.data.message.length > 0) {
            res.message = response.response.data.message;
        }
        else {
            res.message = 'Something went wrong.';
        }

        return res;

    }

}

export default callAPI;