import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
    dealersList: [],
    loader: false,
    dataExists: false,
}

const DocumentCollectionDealersReducer = createSlice({
    name: "DocumentCollectionDealersReducer",
    initialState: defaultState,
    reducers: {
        setTrackerDocumentCollectionDealers: (state, action) => {
            state = {
                ...state,
                dealersList: action.payload,
            }
            return state;
        },
        trackerDCL: (state, action) => {
            state = {
                ...state,
                loader: action.payload,
            }
            return state;
        },
        setTrackerDCLExists: (state, action) => {
            state = {
                ...state,
                 dataExists: action.payload,
            }
            return state;
        }
    }
})

export const { setTrackerDocumentCollectionDealers, trackerDCL, setTrackerDCLExists } = DocumentCollectionDealersReducer.actions

export default DocumentCollectionDealersReducer.reducer;